/*@font-face {
  font-family: 'Grota Thin';
  src: url('../fonts/GrotaSansThin.eot');
  src: local('☺'), url('../fonts/GrotaSansThin.woff') format('woff'), url('../fonts/GrotaSansThin.ttf') format('truetype'), url('../fonts/GrotaSansThin.svg') format('svg');
}

@font-face {
  font-family: 'Grota Book';
  src: url('../fonts/GrotaSansBook.eot');
  src: local('☺'), url('../fonts/GrotaSansBook.woff') format('woff'), url('../fonts/GrotaSansBook.ttf') format('truetype'), url('../fonts/GrotaSansBook.svg') format('svg');
}

@font-face {
  font-family: 'Grota Bold';
  src: url('../fonts/GrotaSansBold.eot');
  src: local('☺'), url('../fonts/GrotaSansBold.woff') format('woff'), url('../fonts/GrotaSansBold.ttf') format('truetype'), url('../fonts/GrotaSansBold.svg') format('svg');
}
@import '../fonts/texgyreheros_regular_macroman/stylesheet';
@import '../fonts/texgyreheros_bold_macroman/stylesheet';
@import '../fonts/texgyreheroscn_regular_macroman/stylesheet';
*/

@font-face {
  font-family: 'texgyreherosregular';
  src: url('../fonts/texgyreheros_regular_macroman/texgyreheros-regular-webfont.eot');
  src: url('../fonts/texgyreheros_regular_macroman/texgyreheros-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/texgyreheros_regular_macroman/texgyreheros-regular-webfont.woff') format('woff'),
  url('../fonts/texgyreheros_regular_macroman/texgyreheros-regular-webfont.ttf') format('truetype'),
  url('../fonts/texgyreheros_regular_macroman/texgyreheros-regular-webfont.svg#texgyreherosregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'texgyreherosbold';
  src: url('../fonts/texgyreheros_bold_macroman/texgyreheros-bold-webfont.eot');
  src: url('../fonts/texgyreheros_bold_macroman/texgyreheros-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/texgyreheros_bold_macroman/texgyreheros-bold-webfont.woff') format('woff'),
  url('../fonts/texgyreheros_bold_macroman/texgyreheros-bold-webfont.ttf') format('truetype'),
  url('../fonts/texgyreheros_bold_macroman/texgyreheros-bold-webfont.svg#texgyreherosbold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'texgyreheroscnregular';
  src: url('../fonts/texgyreheroscn_regular_macroman/texgyreheroscn-regular-webfont.eot');
  src: url('../fonts/texgyreheroscn_regular_macroman/texgyreheroscn-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/texgyreheroscn_regular_macroman/texgyreheroscn-regular-webfont.woff') format('woff'),
  url('../fonts/texgyreheroscn_regular_macroman/texgyreheroscn-regular-webfont.ttf') format('truetype'),
  url('../fonts/texgyreheroscn_regular_macroman/texgyreheroscn-regular-webfont.svg#texgyreheroscnregular') format('svg');
  font-weight: normal;
  font-style: normal;

}




@mixin sans-regular {
  font-family: 'Helvetica', 'texgyreherosregular', sans-serif;
  font-weight: 400;
}

@mixin sans-light {
  font-family: 'Helvetica-Light', 'texgyreheroscnregular', sans-serif;
  font-weight: 300;
}

@mixin sans-bold {
  font-family: 'Helvetica-Bold', 'texgyreherosbold', sans-serif;
  font-weight: 700;
}