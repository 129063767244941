// ========== Global ==========

$background-dark: black;
$background-gray: #ebebeb;
$background-white: white;
$text-dark: #636363;
$text-gray: #959595;
$text-white: white;

$rose-gold:#dfb1a0;
$jet-black:#363636;
$rich-teal:#00655e;
$deep-blue:#470fff;

@mixin centerAbsolute(){
    margin: auto;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ampsair.jamaudio_international_all {
    * {
        box-sizing: border-box;
        padding:0;
        margin:0;
    }
    .mobile-only{
        display:none;
    }
    img {
        max-width: 100%;
    }
    a{
        cursor: pointer;
    }
    a.shop {
        text-transform: uppercase;
        background-color: #00adef;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 65px;
        padding-right: 65px;
        font-size: calc(14px + 0.3vw);
        color: white;
        display: inline-block;
        text-decoration: none;
        width: auto;
        text-align: center;
    }
    h1,
    h2,
    h3{
        text-transform: uppercase;
        display: block;
    }
    h1 {
        font-size: calc(50px + 4vw);    // ~100px @ 1400px width (mockup width)
    }
    h2 {
        font-size: calc(22px + 2vw);    // ~51px @ 1400px width
    }
    h3 {
        font-size: calc(18px + 1.5vw);  // ~38px @ 1400px width
    }
    span.subtitle {
        display: block;
        font-size: calc(8px + 1vw);     // ~22px @ 1400px width
        font-weight: 300;
    }
    .section{
        display: block;
        text-align: center;
        position: relative;
    }
    .copy{
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
        z-index: 3;
        & > *{
            width: 50vw;
            margin: 0 auto;
        }
    }
    .columnl,
    .columnr{
        display: inline-block;
        vertical-align: top;
        width:49%;
    }
    .columnl{
        text-align: left;
    }
    .columnr{
        text-align: right;
    }

    @mixin text-color($color){
        color:$color;
        h1, h2, h3, h4, h5, h6,
        .subtitle{
            color:$color;
        }
    }

    .background-dark{
        @include text-color($text-white);
        background:$background-dark;
    }
    .background-gray{
        @include text-color($text-dark);
        background: $background-gray;
        .subtitle{
            @include text-color($text-gray);
        }
    }
    .background-white{
        @include text-color($text-dark);
        background: $background-white;
        .subtitle{
            @include text-color($text-gray);
        }
    }

    ol.flex-control-nav{
        padding-left: 0;
        width:auto;
        left:0;
        right:0;
        margin:auto;
    }

    .flex-control-paging li a{
        width:12px;
        height:12px;
        background: #434343;
        &.flex-active{
            background: $text-gray;
            border-color: $text-gray;
        }
        &:hover{
            border-color: $text-gray;
        }
    }

    .flex-control-nav li{
        margin:0 6px;
    }

}

// ========== Section-specific ==========
.ampsair.jamaudio_international_all {

    .intro {
        height: 45vw;
        .copy {
            top: 20%;
            left: 5vw;
            text-align: left;
        }
        .subtitle {
            color: $text-dark;
            padding-top: 20px;
            padding-bottom: calc(12px + 5vw);
            font-size: calc(12px + 2vw); // ~40px @ 1400px width
            width: 40vw;
            font-weight: 300;
            margin-left: 0;
        }
        img#intro-title {
            width: 40vw;
            margin-left: 0;
        }
        img.intro-banner {
            max-width: none;
        }

    }

    .videos {
        padding-top:50px;
        padding-bottom:50px;
        .video{
            display: inline-block;
            width: 47vw;
            height: 25vw;
            margin-left: 1vw;
            margin-right: 1vw;
        }
        iframe{
            margin: auto;
            width: 100%;
            height: 100%;
            max-width: 800px;
            max-height: 500px;
        }

    }

    .true-wireless-freedom {
        .copy {
            bottom: 20px;
            color: $text-gray;
        }
        .subtitle {
            padding-top: 2vw;
            padding-bottom: 2vw;
        }
    }

    .design-to-be-noticed {
        .copy {
            top: 90px;
        }
        .subtitle {
            padding-top: 2vw;
            font-weight: 300;
        }
    }

    .super-sized-sound {
        height: 30vw;
        img {
            height: 30vw;
        }
        .copy {
            top: 10vw;
            & > * {
                width: 45vw;
            }
        }
    }

    .resistant {
        .copy {
            bottom: 8vw;
            text-align: right;
            & > * {
                margin-right: 5vw;
            }
        }
        .subtitle {
            padding-top: 1.5vw;
            max-width: 30vw;
            min-width: 300px;
        }
    }

    .wont-fall-out {
        max-height: 600px;
        height: 50vw;
        .copy {
            text-align: left;
            top: 15vw;
            & > * {
                margin-left: 8vw;
                width: 35vw;
            }
        }
        .subtitle {
            padding-top: 2vw;
            line-height: 2vw;
        }
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 5vw;
            margin: auto;
            width: 45vw;
        }

    }

    .battery {
        max-height: 600px;
        height: 50vw;
        .copy {
            text-align: left;
            top: 5vw;
            & > * {
                margin-left: 8vw;
                width: 35vw;
            }
        }
        .subtitle {
            padding-top: calc(16px + 0.5vw);
            line-height: calc(16px + 0.5vw);
        }
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 5vw;
            margin: auto;
            width: 75vw;
        }
        h3:nth-of-type(2) {
            padding-top: 6vw;
        }

    }

    .maximum-comfort {
        height: 50vw;
        img {
            @include centerAbsolute();
            top:auto;
        }
        .copy {
            top: 5vw;
        }
        .subtitle {
            width: 40vw;
            line-height: 2.5vw;
            padding-top: 1.5vw;
        }
    }

    .charger{
        .copy{
            text-align: left;
            width: auto;
            bottom: 15vw;
            right: 10vw;
            & > * {
                width: 35vw;
            }
        }
        .subtitle{
            padding-top:2vw;
        }
    }


    .control{
        .copy{
            text-align: left;
            width: auto;
            bottom: 10vw;
            left: 5vw;
            line-height:1.5vw;
            & > * {
                width: 35vw;
            }
        }
        h3{
            line-height: calc(12px + 2vw);
        }
        .subtitle{
            padding-top:2vw;
            line-height: calc(12px + 1.5vw);
        }
    }


    .be-finally-free {
        height: 50vw;
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -20vw;
            width:100vw;
            margin: auto;
        }
        .copy {
            $top:5vw;
            $firstheight:10vw;
            text-align: left;
            width: 35vw;
            top: $top;
            max-height:10vw;
            right: 0;
            &.labels{
                max-height:none;
                top:$top + $firstheight
            }
        }
        .subtitle {
            padding-top: 1.5vw;
        }

        .color-label,
        .color-value {
            text-transform: uppercase;
            display: inline-block;
            padding-top: 7vw;
            font-size: calc(8px + 1vw); // ~22px @ 1400px width
            width: auto;
        }
        .color-label {
            color: #1eb2ef;
        }
        .color-value {
            color: $text-gray;
        }

        ul.available-colors {
            display: block;
            padding:0;
            padding-top:1.5vw;
            @include noselect();
        }
        li.available-color {
            display: inline-block;
            $circleSize:50px;
            width: $circleSize;
            height: $circleSize;
            border-radius: $circleSize;
            margin-right: $circleSize / 2;
            @include noselect();
            &:hover{
                border:4px solid white;
                cursor:pointer;
            }
        }

        img.option{
            display:none;
        }
        img.option:nth-of-type(1){
            display:inline-block;
        }

        a.shop{
            margin-top:1.5vw;
        }

    }


}

// ========== Mobile ==========
@include breakpoint(980px){ // equivalent to bp_large at time of implementation

    $sectionHeightLarge:600px;
    $sectionHeightMed:500px;
    $sectionHeightSmallPlus:450px;
    $sectionHeightSmall:400px;
    $inset-top:40px;
    $inset-left:2.5vw;
    .ampsair.jamaudio_international_all {
        // --- Global-ish ---

        .desktop-only{
            display:none;
        }
        .mobile-only{
            display:inline-block;
        }
        .section {
            height:$sectionHeightSmall;
            .copy {
                text-align: left;
                top:auto;
                bottom:auto;
                & > * {
                    width: 90vw;
                    margin-left: $inset-left;
                }
            }
            .subtitle{
                line-height:normal;
                max-width:60vw;
            }
            a.shop{
                width:140px;
                height: 30px;
                padding:0;
                line-height: 30px;
                font-size:14px;
            }
        }

        // --- Section-specific ---
        $slideHeight:500px;
        ul.slides{
            max-height:$slideHeight;
            overflow:hidden;
            background:black;
        }

        .intro {
            .copy {
                top: 20px;
                width:300px;
                left:0;
                right:0;
                margin:auto;
                margin-left: $inset-left;
                & > *{
                    margin-left: 0;
                }
            }
            .subtitle{
                font-size:18px;
                max-width:250px;
                padding-bottom:25px;
            }
            img#intro-title {
                width: 300px;
            }
            img.intro-banner{
                @include centerAbsolute();
            }
        }
        
        .videos{
            padding:0;
            height:auto;
            .video{
                display:block;
                margin: auto;
                width:100%;
                height: 56vw;   // 16:9 aspect ratio
                iframe{
                    max-width:none;
                    max-height:none;
                }
            }
        }
        .true-wireless-freedom {
            height:auto;
            .copy{
                top:20px;
                a:nth-of-type(1){
                    margin-left: 5vw;
                }
            }
            .subtitle{
                line-height:18px;
            }
            img{
                width: 100%;
                padding-top:160px;
            }
        }
        .design-to-be-noticed{
            height: auto;
            img{
                padding-top:20px;
                padding-bottom:50px;
            }
            .copy{
                position: static;
                padding-bottom:50px;
            }
        }
        .super-sized-sound{
            height: $sectionHeightSmallPlus;
            .copy{
                bottom:40px;
            }
            img{
                margin-top: 25px;
                height: 180px;
            }
        }
        .resistant{
            height:auto;
            .copy{
                bottom: 40px;
                & > *{
                    margin-left: 2vw;
                }
            }
            h3{
                max-width:80vw;
            }
            img{
                width:100%;
            }
        }
        .wont-fall-out{
            .copy{
                bottom:40px;
            }
            img{
                @include centerAbsolute();
                width: auto;
                height: 160px;
                max-width: none;
                margin-top: 0;
            }
        }
        .maximum-comfort{
            .copy{
                top:30px;
            }
            img{
                position: absolute;
                left: -200px;
                height: 300px;
                width: auto;
                max-width: none;
            }
        }
        .battery{
            height:$sectionHeightMed;
            background:$background-gray;
            .copy{
                top:30px;
            }
            img{
                position: absolute;
                bottom: -140px;
                left: -260px;
                right: 0;
                margin: auto;
                height: 400px;
                width: auto;
                max-width: none;
            }
        }
        .charger{
            height:auto;
            .copy{
                right:auto;
                top:$inset-top;
                left:$inset-left;
            }
            img{
                width:100%;
            }
        }
        .control{
            height: auto;
            h3{
                line-height:normal;
            }
            .copy{
                top:$inset-top;
                left:$inset-left;
            }
            img{
                width:100%;
            }
        }
        .be-finally-free{
            .copy{
                right:auto;
                top:30px;
                left:$inset-left;
                &.labels{
                    top: auto;
                    bottom: 15px;
                    width: auto;
                    text-align: center;
                }
                & > *{
                    margin:auto;
                    text-align: center;
                }
            }
            h3{
                font-size:26px;
            }
            .subtitle{
                font-size:13px;
                max-width:none;
            }
            .color-label,
            .color-value{
                width:auto;
                font-size:14px;
            }
            li.available-color{
                $circleSizeMobile:25px;
                width:$circleSizeMobile;
                height:$circleSizeMobile;
                margin-left: 7px;
                margin-right: 7px;
            }
            img{
                max-width: 600px;
                width: 100%;
                top:-50px;
                left: 0;
                right: 0;
                bottom:0;

            }
            a.shop{
                margin-top:20px;
            }
        }


    }




}



