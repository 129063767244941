.sidebar .block-account .block-title strong {
  font-size: 18px;
  line-height: 23px;
  color: #111;
  @include sans-regular;
}

.sidebar .block-account li.current {
  @include sans-regular;
}

.sidebar .block-account .block-content {
  @include sans-light;
}

.customer-account-index .sub-title {
  @include sans-regular;
  font-weight: bold;
}