/* ---PRODUCT VIEW PAGE--- */

.catalog-product-view .product-view .product-shop h1 {
  font-size: 2vw;
  line-height: 100%;
  @include sans-bold;
  margin-bottom: .4vw;
}

.catalog-product-view .product-view .product-shop .price {
  @include sans-bold;
}

.catalog-product-view .product-view .product-shop .reviews a#review-link {
  color: #40b4e5;
  text-decoration: none;
  font-size: 1.1rem;
  font-family: Oswald;
}

.catalog-product-view .product-view .product-shop .short-description {
  font-size: .9rem;
  padding: 1em 0;
  margin-bottom: 0px;
  margin-top: 10px;
  clear: right;
  border-top: 1px solid #b5b6ba;
  border-bottom: 1px solid #b5b6ba;
  @include sans-light;
  font-weight: 300;
}

.catalog-product-view .product-view .product-shop .sku {
  text-align: left;
  display: block;
}

.catalog-product-view .product-view .product-shop h3.productpage_tagline {
  @include sans-light;
  font-size: 1.5vw;
  line-height: 100%;
  color: #333;
}

.catalog-product-view .product-shop label {
  @include sans-bold;
}
.catalog-product-view .product-shop .add-to-box {
  padding-top: 14px;
}

.product-view .product-shop .add-to-cart {
  margin-top: 25px;
}

.product-view .product-shop .add-to-links {
  display:none;
}

#qty-input-product input.qty {
  text-align: center;
  @include sans-regular;
  font-size: .8rem;
}

.product-view .add-to-cart button.btn-cart span span {
  @include sans-light;
}

.catalog-product-view .product-view .features.row .features-inner {
  @include sans-regular;
  max-width: 960px;
  margin: 0 auto;
  .lft {
    width: 40%;
    float: left;
    padding-top: 30px;
    padding-left: 0px;
    .std ul li {
      margin-top: 15px;
      @include sans-light;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      span {
        @include sans-regular;
        font-weight: bold;
        text-transform: uppercase;
        color: #000;
        font-size: 22px;
        display: block;
      }
    }
  }
  .rght {
    text-align: center;
    padding-top: 30px;
    width: 60%;
    float: left;
    padding-left: 0px;
    img {
      display: inline-block;
    }
  }
}

.reviews.row .reviews .reviews-header {
  @include sans-regular;
  border-bottom: 0px solid #b5b6ba;
}

@media screen and (max-width: 980px) {
  .catalog-product-view .product-view .features.row .features-inner {
    max-width: 700px;
    margin: 0 auto;
  }
  .reviews.row .reviews .reviews-header {
    padding: 0px 0px;
  }
}

@media screen and (max-width: 740px) {
  .catalog-product-view .product-view .features.row .features-inner .lft {
    width: 80%;
    float: left;
    padding-top: 30px;
    padding: 30px;
  }
}