/*
When you have buttons that users are likely to double-click on, 
you don't want the double-clicking to select a bunch of random things.
Apply ... 

  @include noselect() 

 ... to add the styles to disable the selection on something like a button.

*/
@mixin noselect(){
  -moz-user-select:-moz-none;
  -moz-user-select:none;
  -o-user-select:none;
  -khtml-user-select:none;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
