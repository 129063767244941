body {
  background: #fff;
  @include sans-regular;
  color: #333;
  width: 100%;
  font-weight: normal;
}

h1 {
  font-size: 2.5em;
  font-weight: 300;
  margin-bottom: .5em;
  line-height: 1.1em;
  @include sans-light;
}

.sub-title {
  @include sans-light;
}

.main a.button, .main button.button {
  background-color: #000;
}

.main a.button > span > span, .main button.button > span > span {
  background-color: transparent;
  height: auto;
  padding: 0;
  line-height: 100%;
  text-shadow: none;
  @include sans-light;
  font-weight: 300;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0px;
  vertical-align: middle;

  padding: 2px 5px;
}


.main.col1-layout, .col2-left-layout, .col2-right-layout, .col3-layout {
  padding-top: 97px;
}


.header .logo img {
  height: 69px;
}

.header-container{
  background: #fff;
  .nav-container {
    font-size:12px;
  }
  .branding{
    padding-top:0px;
  }
}

.header-promo-bar {
  display: block;
  width: 100%;
  background: #000000;
  text-align: center;
  margin: 0;
  padding: 7px 0;
}
.header-promo-bar .content {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: 'Helvetica-Light', 'texgyreheroscnregular', sans-serif;
  font-size: 14px;
}

.topbar-container {
  background-color: #f1f2f2;
  @include sans-regular;
  font-weight: 300;
  padding-top: 8px;

  .top-bar-inner {
    #countrySelect {
      color: #000;
    }
    #countrySelect a {
      cursor: pointer;
    }
    .countrySelectButton {
      background:url('../images/icon_world_black.png') no-repeat left;
    }
  }

}

ul.links li a{
  color: #000;
}

.topbar-container .top-bar-inner ul.links li a {
  background: url('../images/icon_account-b.png') no-repeat left;
}

#nav li.level-top {
  border-left: 0px solid #D9D1CE;
  &:last-child {
    border-right: 0px solid #D9D1CE;
  }


  &:hover {
    background-color: #f1f2f2;
    opacity: 1;
    transition: background 125ms ease-in 0s;
  }

}

.home-cms-bar {
  background-color: #FFF;
  @include sans-light;
  .container{
    &> div h3{
      color:#000;
    }
  }

}

.header-container .branding {
  height: 68px;
}
#nav a.level-top {
  float: left;
  padding: 5px 20px 5px 20px;
  color: #454545;
  transition: color 125ms ease-in 0s;
  text-transform: uppercase;
  font-weight: bold;
  @include sans-bold;
}
.header .logo img {
  height: 68px;
}

.jamaudio_international_all {
  .fullwidthbanner {
    img.desktop, img.mobile {
      max-width: 100%;
      max-height: inherit;
      width: auto;
    }

    .slider-text {
      .line1 {
        @include sans-bold;
        color: #FFF;
        text-transform: uppercase;
        font-size: 70px;
        font-weight: bold;
        display: block;
        line-height: normal;
      }
      .line2 {
        @include sans-light;
        color: #FFF;
        text-transform: uppercase;
        font-size: 35px;
        display: block;
        line-height: normal;
      }
      a.button {
        padding: 1vw 1.3vw;
        height: auto;
        font-size: 1.3vw;
        margin-top: 10px;
      }
    }
  }
  .video-wrapper{
    text-align:center;
    iframe {
      width: 100%;
      height:55vw;  // 16:9 aspect ratio
    }
  }
}


.cms-index-index .fullwidthbanner-wrapper .flexslider .flex-control-nav{
  display: none;
}


@media screen and (max-width: 980px){
  #nav a.level-top {
    @include sans-bold;
  }
}
@media screen and (max-width: 990px) {
  .topbar-container .topbar .nav-container #nav a.level-top,
  .header-container .header .nav-container #nav a.level-top {
    padding: 5px 10px 5px 10px;
  }
}

@media screen and (max-width: 700px){
  #nav{
    a.level-top {
      float: none;
    }
    li.level-top {
      margin-top: 0px;
      padding-top: 10px;
    }
  }
}