/* ---CHECKOUT--- */

#checkout-step-login h4 {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin: 0 0 15px;
}

#checkout-step-login .btn-only a {
  font-size: .7em;
}


#checkout-step-login .form-list .note {
  display: inline-block;
  padding-left: 110px;
  font-size: .7em;
}

.opc dl.sp-methods label {
  font-weight: 300;
}

#checkout-payment-method-load .form-list label {
  display: inline-block;
  text-align: left;
}


.paypal-express-review {

  h2.legend {
    color: $sol-blue;
  }

  .info-items h2.legend,
  .col-1 h3 {
    a {
      color: $sol-blue;
    }
  }

  .info-shipping .col-2 h3 {
    color: $sol-blue;
  }

}
