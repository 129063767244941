/* line 39, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.big_white {
  @include sans-regular;
}

/* line 56, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.big_orange {
  @include sans-regular;
}

/* line 72, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.big_black {
  @include sans-regular;
}

/* line 88, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.medium_grey {
  @include sans-regular;
}

/* line 105, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.small_text {
  @include sans-regular;
}

/* line 120, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.medium_text {
  @include sans-regular;
}

/* line 135, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.large_text {
  @include sans-regular;
}

/* line 149, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.large_black_text {
  @include sans-regular;
}

/* line 163, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.very_large_text {
  @include sans-regular;
}

/* line 178, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.very_large_black_text {
  @include sans-regular;
}

/* line 191, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.bold_red_text {
  @include sans-regular;
}

/* line 204, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.bold_brown_text {
  @include sans-regular;
}

/* line 217, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.bold_green_text {
  @include sans-regular;
}

/* line 231, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.very_big_white {
  @include sans-regular;
}

/* line 248, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.very_big_black {
  @include sans-regular;
}

/* line 266, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.modern_medium_fat {
  @include sans-regular;
}

/* line 279, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.modern_medium_fat_white {
  @include sans-regular;
}

/* line 292, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.modern_medium_light {
  @include sans-regular;
}

/* line 305, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.modern_big_bluebg {
  @include sans-regular;
}

/* line 320, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.modern_big_redbg {
  @include sans-regular;
}

/* line 336, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.modern_small_text_dark {
  @include sans-regular;
}

/* line 373, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_themepunch-settings.scss */
.tp-caption.black {
  @include sans-regular;
}

/* line 164, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/flexslider/_flexslider.scss */
.jamaudio_international_all .slider-text.oswald {
  @include sans-bold;
}

/* line 175, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/flexslider/_flexslider.scss */
.jamaudio_international_all .slider-text.oswald .line2 {
  @include sans-light;
}

/* line 189, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/flexslider/_flexslider.scss */
.jamaudio_international_all .slider-text.oswald a.button {
  @include sans-regular;
}

/* line 136, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/mobilenav/_sidetogglemenu.scss */
#nav li a {
  @include sans-regular;
}

/* line 9, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
body {
  @include sans-regular;
}

/* Headings */
/* line 19, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
h1 {
  @include sans-light;
}

/* line 161, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
.catalog-product-compare-index button.button span span {
  @include sans-light;
}

/* line 342, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
.sub-title {
  @include sans-light;
}

/* line 542, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
.header .quick-access .cart-link li {
  @include sans-regular;
}

/* line 544, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
.header .quick-access #mini-cart a {
  @include sans-regular;
}

/* line 751, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
.info-box .box-title a {
  @include sans-light;
}

/* line 760, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
.sidebar .block-account .block-title strong {
  @include sans-regular;
}

/* line 761, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
.sidebar .block-account .block-content {
  @include sans-light;
}

/* line 765, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/legacy/_styles.scss */
.sidebar .block-account li.current {
  @include sans-regular;
}

/* line 171, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_header.scss */
#nav a.level-top {
  @include sans-regular;
}

/* line 321, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_header.scss */
.top-cart .block-content .block-subtitle {
  @include sans-light;
}

/* line 388, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_header.scss */
.top-cart .actions button.button > span > span {
  @include sans-light;
}

/* line 456, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_header.scss */
.header-search .form-search input.input-text {
  @include sans-light;
}

/* line 468, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_header.scss */
.header-search .form-search button {
  @include sans-regular;
}

/* line 3, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_body.scss */
.home-cms-bar {
  @include sans-light;
}

/* line 12, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_body.scss */
.home-cms-bar .container > div h3 {
  @include sans-light;
}

/* line 15, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_body.scss */
.home-cms-bar .container > div h3 a.more, .home-cms-bar .container > div h3 a.more:visited {
  @include sans-light;
}

/* line 98, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_body.scss */
.home-cms-columns > .container > div .text .line1 {
  @include sans-regular;
}

/* line 101, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_body.scss */
.home-cms-columns > .container > div .text .line2 {
  @include sans-light;
}

/* line 104, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_body.scss */
.home-cms-columns > .container > div .text .button {
  @include sans-light;
}

/* line 142, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_body.scss */
.home-cms-columns.stand-alone-text > .container > div .text .line1 {
  @include sans-light;
}

/* line 143, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_body.scss */
.home-cms-columns.stand-alone-text > .container > div .text .line2 {
  @include sans-light;
}

/* line 2, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/home/_video.scss */
.video .overlay-container {
  @include sans-regular;
}

/* line 9, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_letsGetSocial.scss */
.lets_get_social h2 {
  @include sans-regular;
}

/* line 74, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_footer.scss */
.footer .footer-nav h3 {
  @include sans-regular;
}

/* line 88, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_footer.scss */
.form-subscribe label {
  @include sans-regular;
}

/* line 96, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_footer.scss */
.form-subscribe button.button {
  @include sans-regular;
}

/* line 38, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_banner.scss */
.category_banner .img .text .line1 {
  @include sans-bold;
}

/* line 43, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_banner.scss */
.category_banner .img .text .line2 {
  @include sans-light;
}

/* line 47, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_banner.scss */
.category_banner .img .text .line2 strong {
  @include sans-regular;
}

/* line 38, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_product_grid.scss */
.category-intro h1 {
  @include sans-regular;
}

/* line 57, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_product_grid.scss */
.products-grid .price-box {
  @include sans-light;
}

/* line 60, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_product_grid.scss */
.category-products .product-name a {
  @include sans-light;
}

/* line 61, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_product_grid.scss */
.category-products .product-name a strong {
  @include sans-bold;
}

/* line 65, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_product_grid.scss */
.products-grid .prices-wrap .buy-now {
  @include sans-bold;
}

/* line 66, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_product_grid.scss */
.products-list .buy-now {
  @include sans-bold;
}

/* line 68, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/_product_grid.scss */
.products-grid .tagline {
  @include sans-regular;
}

/* line 8, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-shop label {
  @include sans-regular;
}

/* line 31, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .product-shop h1 {
  @include sans-light;
}

/* line 41, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .product-shop h1 strong {
  @include sans-regular;
}

/* line 52, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .product-shop h3.productpage_tagline {
  @include sans-regular;
}

/* line 76, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .product-shop .price {
  @include sans-regular;
}

/* line 89, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .product-shop .reviews a#review-link {
  @include sans-regular;
}

/* line 163, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .features.row .features-inner {
  @include sans-regular;
}

/* line 179, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .features.row .features-inner .lft .std ul li {
  @include sans-regular;
}

/* line 185, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .features.row .features-inner .lft .std ul li span {
  @include sans-regular;
}

/* line 219, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .in-the-box.row .in-the-box-inner {
  @include sans-regular;
}

/* line 226, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .in-the-box.row .in-the-box-inner h5 {
  @include sans-regular;
}

/* line 240, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .in-the-box.row .in-the-box-inner .rght .box-detail-links li {
  @include sans-regular;
}

/* line 266, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .techspecs.row .techspecs-inner {
  @include sans-light;
}

/* line 272, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.catalog-product-view .product-view .techspecs.row .techspecs-inner h5 {
  @include sans-regular;
}

/* line 441, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.product-view .add-to-cart button.btn-cart span span {
  @include sans-light;
}

/* line 449, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
.product-view .add-to-cart button.btn-cart span span:before {
  @include sans-regular;
}

@media screen and (max-width: 700px) {
  /* line 694, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
  .catalog-product-view .product-view p.mobile-name {
    @include sans-light;
  }
  /* line 700, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_view.scss */
  .catalog-product-view .product-view p.mobile-name strong {
    @include sans-bold;
  }
}

/* line 31, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_qty.scss */
#qty-input-product input.qty {
  @include sans-regular;
}

/* line 24, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_reviews.scss */
.reviews.row .reviews .reviews-header {
  @include sans-regular;
}

/* line 87, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_reviews.scss */
.reviews.row .box-collateral.box-reviews .box-content .review-item .lft {
  @include sans-regular;
}

/* line 107, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_reviews.scss */
.reviews.row .box-collateral.box-reviews .box-content .review-item .rght h5 {
  @include sans-regular;
}

/* line 4, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_boxdetail.scss */
.catalog-product-view .in-the-box .in-the-box-inner .box-detail {
  @include sans-light;
}

/* line 10, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_boxdetail.scss */
.catalog-product-view .in-the-box .in-the-box-inner .box-detail-links li {
  @include sans-light;
}

/* line 1, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_compare.scss */
.toggle-compare-btn {
  @include sans-light;
}

/* line 10, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_compare.scss */
.toggle-compare-btn > span:before {
  @include sans-light;
}

/* line 61, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_compare.scss */
.compare-loading-message .content-bounds .load-msg {
  @include sans-light;
}

/* line 63, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_compare.scss */
.compare-loading-message .content-bounds .load-msg strong {
  @include sans-regular;
}

/* line 86, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_compare.scss */
.product-compare-info .row .col.label h3 {
  @include sans-regular;
}

/* line 129, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/catalog/product/_compare.scss */
.product-compare-info .row .col.buttons > div {
  @include sans-light;
}

/* line 9, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/checkout/_cart.scss */
.checkout-cart-index .col1-layout .col-main .cart .page-title h1 {
  @include sans-light;
}

/* line 10, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/checkout/_cart.scss */
.checkout-cart-index .col1-layout .col-main .cart .cart-collaterals h2 {
  @include sans-light;
}

/* line 94, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/checkout/_cart.scss */
.checkout-cart-index .page .cart button.button > span > span {
  @include sans-light;
}

/* line 113, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/checkout/_cart.scss */
.checkout-cart-index .page .cart {
  button.button.btn-continue,
  button.button.btn-update {
    & > span > span {
      @include sans-regular;
    }
  }
}

/* line 159, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/checkout/_cart.scss */
.checkout-cart-index .page .cart #discount-coupon-form button.button.check-gc-status > span > span, .checkout-cart-index .page .cart #giftcard-form button.button.check-gc-status > span > span {
  @include sans-regular;
}

/* line 21, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_buttons.scss */
.main a.button > span > span, .main button.button > span > span {
  @include sans-light;
}

/* line 5, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_simple_messages.scss */
ul.messages > li {
  @include sans-regular;
}

/* line 7, /var/www/html/homedics.com/rebuild/dev_1/skin/frontend/enterprise/jam2015_fallback/scss/global/_simple_messages.scss */
ul.messages > li > ul {
  @include sans-regular;
}