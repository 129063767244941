/* ---PRODUCT CATALOG PAGE CHANGES--- */

.category-products .product-name a {
  @include sans-light;
}

.products-grid li.item {
  padding: 0 2vw;
}

.products-grid .price-box {
  @include sans-light;
}

.products-grid .prices-wrap .buy-now {
  @include sans-bold;
}

.toggle-compare-btn {
  @include sans-light;
}