/*reviews section*/
.reviews.row {
  .reviews {
    .reviews-header {
      .reviews-header-inner {
        .reviews-header-count {
          //width:100%;
          //float:left;
          //text-align:center;
          //margin-top: 1em;
        }

        .reviews-header-title a.button {
          @include sans-regular;

          background:$sol-blue;
          letter-spacing: 0;
          text-shadow: none !important;
          margin-left: -3px;
          border-radius: 3px;
          -moz-border-radius: 3px;
          -webkit-border-radius: 3px;
          color: #fff !important;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6) !important;
          font-size: 15px;
          text-transform:uppercase;
          font-weight: normal !important;
        }
      }
    }
  }
}

#colorbox #cboxClose {
  background:$sol-blue;
}

#inline_reviewform .box-content {
  button.button {

    @include sans-regular;

    float:right;
    background:$sol-blue;
    text-shadow: none !important;
    text-transform:uppercase;
    padding:10px 20px;
    line-height:12px;

  }

  .form-list div {
    margin-top: .5em;
    margin-botton: .5em;
  }
}
