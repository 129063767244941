.footer-container{
  .footer{
    .form-subscribe label {
      @include sans-bold;
    }
    .footer-nav h3 {
      @include sans-bold;
    }

    .footer-bottom{
      @include sans-bold;
      background-color: #f2f2f2;
      .footer-bottom-content{
        .retailer-link{
          a{
            color: #333;
            &:hover {
              color: #333;
            }
          }
        }
        .phone {
          color: #333;
          a{
            color: $sol-blue;
            &:hover {
              color: #333;
            }
          }
        }
        .copyright {
          color: #333; 
          background-image: url('../images/logos/blacksol-small.png');
        }
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .footer-container .footer .footer-bottom .footer-bottom-content .copyright {
    padding-bottom: 3.5rem;
  }
}