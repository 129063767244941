/* ---MINI CART--- */

.top-cart .block-content .block-subtitle {
  font-size: 2em;
  color: #454545;
  line-height: 1.25em;
  padding: 5px 10px;
  @include sans-light;
  font-weight: 300;
  border-bottom: 1px solid #eee;
}

.header .quick-access #mini-cart a {
  color: #333;
  text-transform: uppercase;
  transition: color 125ms ease-in 0s;
  @include sans-regular;
  font-weight: normal;
}

.top-cart .actions button.button > span > span {
  padding: 0;
  height: inherit;
  @include sans-light;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: transparent;
  display: block;
  line-height: inherit;
  color: #fff;
  text-decoration: inherit;
  text-transform: uppercase;
  background-image: none !important;
}

/* ---CART--- */

.checkout-cart-index{
  .col1-layout .col-main .cart .page-title h1 {
    @include sans-regular;
  }
  .page .cart{
    button.button > span > span {
      @include sans-light;
    }
    button.button.btn-proceed-checkout > span > span {
      font-size: 1.1rem;
    }
    button.button.btn-continue,
    button.button.btn-update {
      & > span > span {
        @include sans-regular;
      }
    }
  }
}

.checkout-cart-index .col1-layout .col-main .cart .cart-collaterals h2 {
  @include sans-light;
  font-weight: 300;
}